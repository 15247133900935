slick-dots {
    top: 90% ;
}
.slider-img{
    width: 100% !important;
    height: 380px;
}

.slick-slide{
    height:auto !important;      
}

